import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import CheckHint from "./CheckHint";
import Modal from "./Modal";
import Loading from "./Loading";

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 600,
    },
  },
});

export default function App() {
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState({ active: false });

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="fluid"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", textAlign: "center" }}
      >
        {isLoading ? (
          <Loading />
        ) : modal.active ? (
          <Modal details={modal} />
        ) : (
          <CheckHint setLoading={setLoading} setModal={setModal} />
        )}
      </Container>
    </ThemeProvider>
  );
}
