import React, { useState } from "react";
import { Button, Grid, InputBase, Typography } from "@mui/material";

const hintsAndAnswers = [
  {
    hint: "E1",
    answer: "Brugge",
  },
  {
    hint: "E2",
    answer: "Gent",
  },
  {
    hint: "E3",
    answer: "Brussel",
  },
  {
    hint: "E4",
    answer: "Ardennen",
  },
  {
    hint: "E1H1",
    answer: "Huisbrouwerij De Halve Maan",
    story:
      "In Brugge bestel je niet zomaar een pintje. De Vlaamse stad is beroemd van schuimende klassiekers als 'Brugse Zot', 'Blanche de Bruges' en 'Straffe Hendrik'. Geesteskinderen van de enige stadsbrouwerij van Brugge: De Halve Maan. Al in 1564 vermeldde het stadsregister het bestaan van brouwhuis Die Maene op het Walplein, waarmee dit een van de oudste nog actieve brouwerijen van West-Europa is.",
  },
  {
    hint: "E1H2",
    answer: "Rondvaart door Brugge per boot",
    story:
      "In Brugge bestel je niet zomaar een pintje. De Vlaamse stad is beroemd van schuimende klassiekers als 'Brugse Zot', 'Blanche de Bruges' en 'Straffe Hendrik'. Geesteskinderen van de enige stadsbrouwerij van Brugge: De Halve Maan. Al in 1564 vermeldde het stadsregister het bestaan van brouwhuis Die Maene op het Walplein, waarmee dit een van de oudste nog actieve brouwerijen van West-Europa is.",
  },
  {
    hint: "E1H3",
    answer: "Friet en chocola museum",
    story:
      "De twee grootste culinaire passies van de Belgen komen samen in het Frietmuseum en het chocolademuseum Choco Story. De vraag is: wat valt over deze lekkernijen te vertellen? Maar beide musea duiken serieus in de geschiedenis van respectievelijk de (gesneden) aardappel en cacao. Bekijk de huacos, oude keramische potten uit de Inca-periode in de vorm van aardappelen. Choco Story heeft een collectie van ruim duizend voorwerpen die de geschiedenis van de chocolade tot leven brengen.",
  },
  {
    hint: "E2H1",
    answer: "Kasteel Het Gravensteen",
    story:
      "Het Gravensteen straalt iets ongenaakbaars uit: dit robuuste kasteel uit de 9e en 12e eeuw kwam je niet zomaar binnen. De graven van Vlaanderen verbleven hier, totdat graaf Lodewijk van Male genoeg kreeg van het gebrek aan comfort. Heel toepasselijk zijn het Wapenmuseum en het Gerechtsmuseum er nu gehuisvest. De collectie varieert van middeleeuwse bijlen tot folterwielen. Niet voor tere zieltjes!",
  },
  {
    hint: "E2H2",
    answer: "Gentse lekkernijen",
    story:
      "Aan lekkernijen geen gebrek in Gent: proef de Callas confiture en de Ganda ham. Ook heerlijk: Gentse neuzen of cuberdons (zachte snoepjes) en Gentse waterzooi, een typisch Gents gerecht gemaakt van kip, groenten, room en aardappelen.",
  },
  {
    hint: "E3H1",
    answer: "Manneken Pis en Jeanneke Pis",
    story:
      "Onmisbaar tijdens een bezoek aan de Belgische hoofdstad: Manneken Pis. Dit standbeeld van een plassend jongetje is hét symbool van de Brusselse folklore. Dit grappige manneke is gebaseerd op een komische legende. Het verhaal gaat dat het jongetje over een lont van een enorm kruitvat plaste en zo onbedoeld een explosie voorkwam. Of dit het echte verhaal van Manneken Pis is weet niemand. Sinds de komst van het beeld in 1619 doen er meerdere verhalen de ronde.",
  },
  {
    hint: "E3H2",
    answer: "Atomium",
    story:
      "Het 102 meter hoge Atomium is al vanaf honderden meters afstand zichtbaar. Dit imposante kunstwerk stelt de atomen van een ijzermolecule voor – 150 miljard keer uitvergroot. Maak een wandeling door de bollen verbonden door buizen en bekijk exposities over de Wereldtentoonstelling in 1958, waarvoor het zilverkleurige gebouw destijds is gebouwd.",
  },
  {
    hint: "E3H3",
    answer: "illusion Brussels (toveren)",
  },
  {
    hint: "E3H4",
    answer: "De Koninklijke Serres van Laken",
  },
  {
    hint: "E4H1",
    answer: "GPS Achouffe wandeling",
  },
  {
    hint: "E4H2",
    answer: "Raften op de Ourthe",
  },
  {
    hint: "E4H3",
    answer: "Wildpark van La Roche",
  },
  {
    hint: "E4H4",
    answer: "Speleogie",
  },
  {
    hint: "E4H5",
    answer: "Vlot bouwen",
  },
  {
    hint: "E4H6",
    answer: "Kanoën",
  },
];

export default function CheckHint({ setLoading, setModal }) {
  const [hint, setHint] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSubmission = async () => {
    if (hint) {
      setLoading(true);
      setTimeout(() => {
        let correctGuess = false;
        for (const item of hintsAndAnswers) {
          console.log(hint.length);
          if (hint.toLocaleLowerCase().includes(item.hint.toLocaleLowerCase()) && hint.toLocaleLowerCase().includes("h")) {
            correctGuess = true;
            setModal({ active: true, correct: true, hint: item.hint, activity: item.answer, story: item.story });
          }
          if (
            hint.toLocaleLowerCase().includes(item.hint.toLocaleLowerCase()) &&
            answer.toLocaleLowerCase().includes(item.answer.toLocaleLowerCase())
          ) {
            correctGuess = true;
            setModal({ active: true, correct: true, hint: item.hint, answer: item.answer });
          }
        }

        if (!correctGuess) {
          setModal({ active: true, correct: false, hint, answer });
        }

        setLoading(false);
      }, 3000);
    }
  };

  return (
    <Grid container sx={{ display: "grid", placeItems: "center" }}>
      <Grid item xs={12} py={5}>
        <Typography variant="h1">Check de hint</Typography>
        <Typography variant="subtitle1">Het antwoord kan een stad/gebied zijn of een activiteit</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Vul de hint code in</Typography>
        <InputBase sx={{ py: 1, width: "100%" }} placeholder={`E1`} value={hint} onChange={(e) => setHint(e.target.value)} />
      </Grid>

      {hint && hint.length == 2 && !hint.toLocaleLowerCase().includes("h") && (
        <Grid item xs={12}>
          <Typography variant="body2">Wat denken jullie dat het antwoord is?</Typography>
          <InputBase
            sx={{ py: 1, width: "100%" }}
            placeholder={`Stad/gebied of activiteit`}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </Grid>
      )}

      <Grid item xs={12} py={5}>
        <Button onClick={handleSubmission}>Controleer antwoord</Button>
      </Grid>
    </Grid>
  );
}
