import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

export default function Loading({ details }) {
  return (
    <Grid container sx={{ display: "grid", placeItems: "center" }}>
      <Grid item xs={12} my={1}>
        <Typography>Aan het kijken of het goed is...</Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
