import React from "react";
import { useWindowSize } from "react-use";
import { Grid, Typography } from "@mui/material";
import { CheckCircle as CheckIcon } from "@mui/icons-material";
import Confetti from "react-confetti";

export default function Modal({ details }) {
  const { width, height } = useWindowSize();
  return (
    <Grid container sx={{ display: "grid", placeItems: "center" }}>
      {details.correct && !details.activity ? (
        <Grid item xs={12}>
          <Confetti width={width} height={height} />
          <CheckIcon sx={{ fontSize: 100, color: "green" }} />
          <Typography variant="h1">Helemaal goed!</Typography>
          <Typography variant="subtitle1">
            <strong>{details.answer}</strong> is jullie volgende bestemming!
          </Typography>
        </Grid>
      ) : details.correct && details.activity ? (
        <Grid item xs={12}>
          <Typography variant="h1">{details.activity}</Typography>
          <Typography variant="subtitle1">{details.story}</Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h1">Helaas 😢</Typography>
          <Typography variant="subtitle1">Bekijk de hints nog een keer</Typography>
        </Grid>
      )}
    </Grid>
  );
}
